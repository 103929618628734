var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-nav',{},[_c('b-nav-item',{staticStyle:{"position":"absolute","z-index":"2222","right":"8rem","top":"3.3em"},attrs:{"active":""}},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-folder-classes",modifiers:{"modal-folder-classes":true}}],attrs:{"variant":"info"}},[_vm._v(" folder classes "),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"plus"}})],1)],1),_c('b-nav-item',{staticStyle:{"position":"absolute","z-index":"2222","right":"0","top":"3.3em"},attrs:{"active":""}},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-folder",modifiers:{"modal-folder":true}}],attrs:{"variant":"info"}},[_vm._v(" Add folder "),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"plus"}})],1)],1)],1),(_vm.processing)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"65vh"}},[_c('Spinner')],1):_c('div',[_c('b-nav',{staticClass:"mb-3"},[(_vm.classes2.length)?_c('p',{staticClass:"mt-2 text-info ml-2"},[_vm._v(" Image classes: "+_vm._s(_vm.classes2.join(", "))+" ")]):_c('p',{staticClass:"mt-2 text-danger ml-2"},[_vm._v(" Image classes: No image classes added yet ")]),(!_vm.classes2.length)?_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-imgclass",modifiers:{"modal-imgclass":true}}],staticClass:"mt-2 text-info ml-2",attrs:{"icon":"pen","scale":"1"}}):_vm._e()],1),_c('hr',{staticStyle:{"width":"100vw"}}),(_vm.data && _vm.data.length)?_c('vue-good-table',{staticClass:"content-area",attrs:{"columns":_vm.fields,"rows":_vm.data,"pagination-options":{
        enabled: true,
        perPage: 7,
      },"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('router-link',{staticClass:"text-info",attrs:{"to":("/admin/datasets/" + (_vm.items[1].text) + "/" + (props.row.id))}},[_vm._v(_vm._s(props.row.name))])],1):_vm._e(),(props.column.field == 'labelled')?_c('span',[(props.row.labelled)?_c('b-icon',{attrs:{"icon":"check-circle-fill","scale":"","variant":"info","font-scale":"1em"}}):_c('b-icon',{attrs:{"icon":"X-circle","scale":"","variant":"danger","font-scale":"1rem"}})],1):_vm._e(),(props.column.field == 'id')?_c('span',[_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-delete",modifiers:{"modal-delete":true}}],staticClass:"delete-icon",staticStyle:{"float":"left","font-weight":"bold","font-size":"1.3rem"},attrs:{"icon":"trash","variant":"danger"},on:{"click":function($event){_vm.folderToDelete = props.row.id}}})],1):_vm._e(),(props.column.field == 'images_URLs')?_c('span',[_c('h5',{staticClass:"text-info"},[_vm._v(" "+_vm._s(props.row.images_URLs.length)+" ")])]):_vm._e()]}}],null,false,3654107220)}):_c('h3',{staticClass:"text-danger pt-5"},[_vm._v(" This dataset is currently empty, add folders. ")])],1),_c('b-modal',{attrs:{"id":"modal-delete","centered":"","title":"Delete Dataset","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":_vm.handleFolderDelete}},[_vm._v("Delete")])]}}])},[_c('h4',{staticClass:"text-danger"},[_vm._v(" You are deleting a folder "),_c('b-icon',{attrs:{"icon":"exclamation-circle"}})],1)]),_c('b-modal',{attrs:{"id":"modal-folder","centered":"","title":"Create Folder","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleFolderCreate}},[_vm._v("Submit")])]}}])},[_c('b-form-group',{attrs:{"label":"Folder:","label-cols-sm":"2","label-size":"sm"}},[_c('b-form-file',{attrs:{"id":"file-small","size":"sm","directory":"","multiple":"","accept":"image/jpeg, image/png"},model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1)],1),_c('b-modal',{attrs:{"id":"modal-folder-classes","centered":"","title":"Update Folder Classes","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleFolderClassUpdate}},[_vm._v("Update")])]}}])},[_c('b-form-group',{attrs:{"label":"Folder:","label-cols-sm":"2","label-size":"sm"}},[_c('b-form-input',{attrs:{"placeholder":"Enter classes e.g (Positive, Negative, not sure)"},model:{value:(_vm.folderClasses),callback:function ($$v) {_vm.folderClasses=$$v},expression:"folderClasses"}})],1)],1),_c('b-modal',{attrs:{"id":"modal-imgclass","centered":"","title":"Update Image Classes","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleClassesUpdate}},[_vm._v("Submit")])]}}])},[_c('b-form-input',{attrs:{"placeholder":"Enter classes e.g (Ascetic Acid, No Ascetic Acid)"},model:{value:(_vm.imageClasses),callback:function ($$v) {_vm.imageClasses=$$v},expression:"imageClasses"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }